<template>
    <b-modal
        id="create-puc-modal"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Crear tercero"
        @shown="openModal"
        @close="resetFom"
    >
        <b-card class="mb-4 w-100">
            <div>
                <b-form class="p-5">
                    <div class="d-flex">
                        <div>
                            <h3 class="mb-2">Datos del item del puc</h3>
                            <div class="text-gray mb-5">
                                Por favor registre los datos del item del puc
                                para continuar
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <b-col cols="12" md="3" lg="3" xl="3">
                            <b-form-group>
                                <label for="codigo">Código</label>
                                <b-form-input
                                    id="codigo"
                                    type="text"
                                    placeholder="Ej: 001"
                                    v-model="$v.pucItem.codigo.$model"
                                    :state="validateState('codigo')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.pucItem.codigo.$anyError"
                                >
                                    ({{ $v.pucItem.codigo.$model.length }} /
                                    {{
                                        $v.pucItem.codigo.$params.maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.pucItem.codigo.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.pucItem.codigo.$params.maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.pucItem.codigo.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="9" lg="9" xl="9">
                            <b-form-group>
                                <label for="nombre_comercial"
                                    >Descripción</label
                                >
                                <b-form-input
                                    id="descripcion"
                                    type="text"
                                    placeholder="Ej: Mi item del puc"
                                    v-model="$v.pucItem.descripcion.$model"
                                    :state="validateState('descripcion')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.pucItem.descripcion.$anyError"
                                >
                                    ({{ $v.pucItem.descripcion.$model.length }}
                                    /
                                    {{
                                        $v.pucItem.descripcion.$params.maxLength
                                            .max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.pucItem.descripcion.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.pucItem.descripcion.$params.maxLength
                                            .max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.pucItem.descripcion.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="tipo"
                                    >Tipo de movimiento
                                    <small style="color: red">*</small></label
                                >
                                <!-- <b-form-select
                                    id="tipo"
                                    :options="tiposMovimiento"
                                    text-field="descripcion"
                                    value-field="id"
                                    v-model="$v.pucItem.tipo.$model"
                                    :state="validateState('tipo')"
                                    :disabled="read_only"
                                >
                                    <template #first>
                                        <b-form-select-option :value="null">
                                            Seleccione...
                                        </b-form-select-option>
                                    </template>
                                </b-form-select> -->
                                <jautocomplete
                                    id="tipo"
                                    :items="tiposMovimiento"
                                    item-text="descripcion"
                                    item-value="id"
                                    open-on-focus
                                    v-model="$v.pucItem.tipo.$model"
                                    :state="validateState('tipo')"
                                    :disabled="read_only"
                                />
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.pucItem.tipo.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="tipo"
                                    >Manejo de cuenta
                                    <small style="color: red">*</small></label
                                >
                                <!-- <b-form-select
                                    id="iva_retencion"
                                    :options="manejosCuenta"
                                    text-field="descripcion"
                                    value-field="id"
                                    v-model="$v.pucItem.iva_retencion.$model"
                                    :state="validateState('iva_retencion')"
                                    :disabled="read_only"
                                >
                                    <template #first>
                                        <b-form-select-option :value="null">
                                            Seleccione...
                                        </b-form-select-option>
                                    </template>
                                </b-form-select> -->
                                <jautocomplete
                                    id="iva_retencion"
                                    :items="manejosCuenta"
                                    item-text="descripcion"
                                    item-value="id"
                                    open-on-focus
                                    v-model="$v.pucItem.iva_retencion.$model"
                                    :state="validateState('iva_retencion')"
                                    :disabled="read_only"
                                />
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.pucItem.iva_retencion.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12">
                            <b-form-group v-if="pucItem.iva_retencion !== 'N'">
                                <label for="porcentaje_base"
                                    >Porcentaje base</label
                                >
                                <b-form-input
                                    id="porcentaje_base"
                                    type="number"
                                    placeholder="Ej: 19"
                                    v-model="$v.pucItem.porcentaje_base.$model"
                                    :state="validateState('porcentaje_base')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.pucItem.porcentaje_base.$anyError"
                                >
                                    ({{
                                        $v.pucItem.porcentaje_base.$model
                                            ? $v.pucItem.porcentaje_base.$model
                                                  .length
                                            : '0'.length
                                    }}
                                    /
                                    {{
                                        $v.pucItem.porcentaje_base.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.pucItem.porcentaje_base.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.pucItem.porcentaje_base.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.pucItem.porcentaje_base.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="maneja_centro_costo"
                                    >Maneja centro de costo?</label
                                >
                                <b-form-checkbox
                                    id="maneja_centro_costo"
                                    size="lg"
                                    switch
                                    v-model="
                                        $v.pucItem.maneja_centro_costo.$model
                                    "
                                    :value="true"
                                    :unchecked-value="false"
                                    :disabled="read_only"
                                >
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="maneja_tercero"
                                    >Maneja tercero?</label
                                >
                                <b-form-checkbox
                                    id="maneja_tercero"
                                    size="lg"
                                    switch
                                    v-model="$v.pucItem.maneja_tercero.$model"
                                    :value="true"
                                    :unchecked-value="false"
                                    :disabled="read_only"
                                >
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="maneja_doc_referencia"
                                    >Maneja Documento de Referencia?</label
                                >
                                <b-form-checkbox
                                    id="maneja_doc_referencia"
                                    size="lg"
                                    switch
                                    v-model="
                                        $v.pucItem.maneja_doc_referencia.$model
                                    "
                                    :value="true"
                                    :unchecked-value="false"
                                    :disabled="read_only"
                                >
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <div class="float-right d-flex flex-row">
                                <b-button
                                    variant="primary"
                                    :disabled="isLoading || read_only"
                                    size="md"
                                    v-b-popover.hover.top="
                                        'Presione ctrl+shift+s o cmd+shift+s'
                                    "
                                    class="d-flex align-items-center"
                                    @click="save"
                                >
                                    <feather
                                        type="save"
                                        size="1rem"
                                        class="mr-2 text-blue-active"
                                    />
                                    <span
                                        v-if="isLoading"
                                        class="d-sm-flex align-items-center justify-content-center"
                                    >
                                        <b-spinner small></b-spinner>
                                        <span class="ml-2">Guardando...</span>
                                    </span>
                                    <span v-else>Guardar</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-card>
    </b-modal>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import { filterPuc } from '@/utils/filters'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects
} from '@/utils/others'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'

const { API } = services

const defaultPucItem = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            tipo: null,
            iva_retencion: 'N',
            maneja_centro_costo: false,
            maneja_doc_referencia: false,
            maneja_tercero: false,
            porcentaje_base: 0,
            empresa_id: null
        })
    )

export default {
    name: 'CrearPucModal',
    components: { jautocomplete },
    mixins: [validationMixin],
    data: () => ({
        tiposMovimiento: [
            { id: 'M', descripcion: 'Movimiento' },
            { id: 'S', descripcion: 'Saldo' }
        ],
        manejosCuenta: [
            { id: 'R', descripcion: 'Retención' },
            { id: 'I', descripcion: 'IVA' },
            { id: 'N', descripcion: 'Ninguna' }
        ],
        pucItemCopy: defaultPucItem(),
        pucItem: defaultPucItem(),
        isLoading: false,
        isSaved: true,
        read_only: false
    }),
    watch: {
        pucItem: {
            handler(newValue) {
                this.isSaved = compareObjects(this.pucItemCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        addListenerCommands(this)
        this.getParams()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        pucItem: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            tipo: {
                required
            },
            iva_retencion: {
                required
            },
            porcentaje_base: {
                required: requiredIf((model) => {
                    return model.iva_retencion !== 'N'
                }),
                maxLength: maxLength(5)
            },
            maneja_centro_costo: {},
            maneja_doc_referencia: {},
            maneja_tercero: {}
        }
    },
    methods: {
        showLog(data) {
            console.log(data)
        },
        filterPuc,
        getParams() {
            const { puc_id } = this.$route.params
            if (puc_id) {
                this.getPucItem(puc_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        getPucItem(id) {
            API.POST({
                url: 'contabilidad/puc/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.pucItemCopy = { ...dato }
                        this.pucItem = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.pucItem.$invalid
            if (valid) {
                this.isLoading = true
                const p_opc = this.pucItem.id ? 'UPDATE' : 'INSERT'
                this.pucItem.empresa_id = this.getUltimaEmpresa.id
                API.POST({
                    url: 'contabilidad/puc/crud',
                    data: {
                        p_datajson: {
                            ...this.pucItem
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            // this.back()
                            this.$emit('newPuc', dato)
                            this.resetFom()
                            this.close()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.pucItem.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.pucItem[key]
            return $dirty ? !$error : null
        },
        openModal() {
            // const { empresa_id } = this.$route.params
            // if (empresa_id) {
            //     this.getRetecrees(empresa_id)
            // } else {
            //     this.getRetecrees()
            // }
        },
        resetFom() {
            this.pucItem = defaultPucItem()
            this.$v.pucItem.$reset()
        },
        close() {
            this.$bvModal.hide('create-puc-modal')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 29 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
